<template>
  <div>
    <el-table
      size="medium"
      border
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column prop="order_no" label="订单号" />
      <el-table-column prop="product_title" label="产品标题" width="140" />
      <el-table-column prop="state" label="订单状态" width="80">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.state === 1">正常</el-tag>
          <el-tag size="mini" v-if="scope.row.state === 2" type="warning">排队中</el-tag>
          <el-tag size="mini" v-if="scope.row.state === 5" type="danger">取消</el-tag>
          <el-tag size="mini" v-if="scope.row.state === -1" type="danger">删除</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="user_nick" label="用户昵称" width="120" />
      <el-table-column prop="phone" label="用户手机号" width="120" />
      <el-table-column prop="price" label="订单价格" width="120" />
      <el-table-column prop="pay_money" label="支付价格" width="120" />
      <el-table-column prop="consumer_money" label="消费卡支付价格" width="120" />
      <el-table-column prop="pay_type" label="支付方式/支付状态/支付单号" width="300">
        <template slot-scope="scope">
          <span v-if="scope.row.pay_type === 'BALANCE'">余额支付</span>
          <span v-if="scope.row.pay_type === 'WXPAY'">微信支付</span>
          <span v-if="scope.row.pay_type === 'ALIPAY'">支付宝</span>
          <span v-if="scope.row.pay_type === 'CARD'">商家卡</span>
          <span v-if="scope.row.pay_type === 'SCORE'">积分支付</span>
          <span v-if="scope.row.pay_type === 'CONSUMER_CARD'">消费卡</span>
          /
          <el-tag size="mini" v-if="scope.row.pay_state === 1">已支付</el-tag>
          <el-tag size="mini" v-if="scope.row.pay_state === 0" type="warning"
            >未支付</el-tag
          >
          /{{ scope.row.pay_order_no }}
        </template>
      </el-table-column>
      <el-table-column prop="pay_time" label="支付时间" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.pay_time !== '1970-01-01 08:00:00'">{{
            scope.row.pay_time
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="is_queue_up" label="退款状态/退款金额/退款单号" width="300">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.refund_state === 1" type="warning"
            >有退款</el-tag
          >
          <span v-if="scope.row.refund_state === 1">
            /￥{{ scope.row.refund_money }} /{{ scope.row.refund_order_no }}
          </span>
          <el-tag size="mini" v-if="scope.row.refund_state === 0">无</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_queue_up" label="退款时间/退款备注" width="300">
        <template slot-scope="scope">
          <span v-if="scope.row.refund_time !== '1970-01-01 08:00:00'"
            >{{ scope.row.refund_time }}/</span
          >
          <span>{{ scope.row.refund_remarks }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 20px"></div>
    <el-pagination
      @current-change="getlist"
      :page-size="this.env.pageSize"
      :pager-count="7"
      background
      layout="prev, pager, next, total"
      :current-page.sync="page"
      :total="count"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "OrderList",
  data() {
    return {
      orderId: 0, // 拼团id
      loading: false, // 加载状态
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 添加页面数据
      search: {}, // 搜索内容
    };
  },
  methods: {
    init(id) {
      this.orderId = id;
      this.count = 0;
      this.tableData = [];

      this.is_search();
    },
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 获取拼团订单
    getlist() {
      let postdata = {
        api_name: "marketing.groupbuy.getorderlist",
        token: this.Tool.get_l_cache("token"),
        groupbuy_initiate_uuid: this.orderId,
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
  },
};
</script>

<style scoped></style>
